import { Controller, Application } from 'stimulus';
import { Config } from '../../../config';
import { env, API, FetchClient, Intl, SimpleLogger } from '../../../services';
import { render, html } from 'lit-html';
import { Feed, Timer } from './feed.model';
import { counter } from '../../components/shared/counter/counter.component';

import './feed.scss';
import { subscribers } from './subscribers/subscribers.component';
import { Blog } from '../blogs/blog.model';
import { blogCard } from '../../components/shared/blog-card.component';
import { ElementSelector, Logger } from '../../../types';
import { submit, click } from '../../../utils/dom';
import { Form } from '../../components/form';
import { card } from './cards/card.controller';
import { Route } from '../../../router';

interface Elements {
  form: HTMLElement;
  player: HTMLElement;
}

const factory = (url: string, $: ElementSelector, logger: Logger) =>
  class FeedController extends Controller {
    static targets = ['content'];

    private contentTarget: HTMLElement;

    private elements: Elements;
    private localize: Intl;
    private form: Form;

    initialize() {}

    connect() {
      const api = new API(new FetchClient(url));
      this.localize = Intl.instance;
      api.getFeed().then(res => {
        this.form = new Form($, this.localize.t, api, logger);
        this.initElements();
        this.render(res);
        this.attachListeners();
      });
    }

    private initElements() {
      this.elements = {
        form: $<HTMLAnchorElement>('#form')!,
        player: $<HTMLVideoElement>('#video-iframe')!,
      };
    }

    private attachListeners() {
      const { form } = this.elements;
      submit(form, this.handleFormSubmit);
    }

    private handleFormSubmit = (ev: Event): void => {
      ev.preventDefault();
      this.form.submit();
      this.form.reset();
    };

    private render(feed: Feed) {
      render(this.getContent(feed), this.contentTarget);
    }

    private getContent(feed: Feed) {
      const {
        blogs,
        timer,
        subscribers: { number_of_users, text, active },
      } = feed;

     
      

      return html`
        <div class="feed-container flex flex-wrap justify--center">
          <div class="flex w-100">
            ${this.getBlogs(blogs)}
          </div>
        </div>
      `;
    }


    private getDownloadSection() {
      return html`
        <div class='feed__download-section'>
         aaa
        </div>
      `;
    } 

    private getTimer(timer: Timer[]) {
      const timerTextElement = new DOMParser().parseFromString(
        `${timer[0].text}`,
        'text/html',
      ).firstChild!;


      return  timer[0].active
        ? html`
            <div
              class="feed__counter flex flex-column justify--center pa2 pl5-ns pr5-ns align--center"
            >
              <h4 class="fw2 gray f2 feed__timer-text">
                ${timerTextElement}
              </h4>
              <div class="w-100">
                ${counter(timer[0].end_time)}
              </div>
            </div>
          `
        : ''
    }

    private getSubscribers(numberOfUsers: number, text: string, active: number) {
      const subscriberTextElement = new DOMParser().parseFromString(
        `${text}`,
        'text/html',
      ).firstChild!;

      return  active
        ? html`
            <div class="feed__subscribe flex justify--center pa1">
              ${subscribers(numberOfUsers, subscriberTextElement)}
            </div>
          `
        : ''
    }

    private getBlogWidth(numberOfBlogs: number) {
      if (numberOfBlogs === 1) {
        return 50;
      }
      return 25;
    }

    private getBlogs(blogs: Blog[]) {
      const width = this.getBlogWidth(blogs.length);
      const widthStyle = `width: ${width}%;`;
      return html`
        <div class="flex flex-wrap w-100">
          ${blogs.map(
            blog =>
              html`
                <div style=${widthStyle} class="pa1 feed__blog">
                  ${blogCard(
                    {
                      blog: blog,
                      className: '',
                    },
                    true,
                  )}
                </div>
              `,
          )}
          ${blogs.length <= 3 ? this.getGoToCards(blogs.length) : ''}
        </div>
      `;
    }
    private getGoToCards(numberOfblogs: number) {
      const amount = 4 - numberOfblogs;
      const style = 'width:25%';
      if (amount === 1) {
        return html`
          <div style=${style} class="pa1 feed__blog">
            ${card({
              image: 'images/blog-card.jpeg',
              icon: 'short_text',
              goTo: Route.BLOGS,
              label: 'Check our Blog',
            })}
          </div>
        `;
      }

      if (amount === 2 || amount === 3) {
        return html`
          <div style=${style} title="Comming soon"  class="pa1 feed__blog">
            <div class='w-100 disabled h-100'>
              ${card({
                image: 'images/our-team-card.jpg',
                icon: 'group',
                goTo: Route.OUR_TEAM,
                label: 'Meet our Team',
              })}
            </div>
          </div>
          <div style=${style} class="pa1 feed__blog">
            ${card({
              image: 'images/blog-card.jpeg',
              icon: 'short_text',
              goTo: Route.BLOGS,
              label: 'Check our Blog',
            })}
          </div>
        `;
      }
      return '';
    }
  };

const controller = factory(
  Config.URL(env),
  document.querySelector.bind(document),
  new SimpleLogger(env),
);
Application.start().register('feed', controller);
