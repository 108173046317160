import { Controller, Application } from 'stimulus';
import { html, render } from 'lit-html';

import '../../../blogs';
import './blog.scss';

import { API, FetchClient, env } from '../../../../services';
import { Config } from '../../../../config';
import { Blog } from '../blog.model';
import { RoutingService } from '../../../../services/routing.service';
import moment from 'moment';

const blogControllerFactory = (url: string) =>
  class BlogController extends Controller {
    static targets = ['content'];

    private contentTarget: HTMLElement;

    initialize() {
      const api = new API(new FetchClient(url));
      const slug = RoutingService.router.getState().params.slug;
      console.log(slug);
      if (!slug) {
        throw Error('Slug param not found');
      }
      api.getBlog(slug).then(blog => {
        this.render(blog);
      });
    }

    private getContent(blog: Blog) {
      return html`
        <div class="blog__container flex flex-column justify--center">
          ${this.getTitleHeader(blog)} ${this.getCoverImage(blog)}
          ${blog.subtitle
            ? html`<span class="gray mt2 mb2" style="font-size: 24px" ><i>"${
                blog.subtitle
              }"</i></span>`
            : ''}
          ${this.getText(blog)}
        </div>
      `;
    }

    private shareOnFacebook(blog: Blog) {
      return (e: Event) => {
        const url = `${document.URL}/#/blog/${blog.slug}`;
      
        e.preventDefault();
        FB.ui({
          method: 'share',
          name: blog.title,
          href: url,
          picture:
            blog.image ||
            'https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
          description: blog.short_description || 'No description found.',
          message: '',
        });
        return false;
      };
   }

    private getTitleHeader(blog: Blog) {

      return html`
        <div class="flex justify--space-between">
          <div>
            <label class="section-text gray"
              >${moment(blog.created_at).format('Do MMMM, YYYY')}</label
            >
            <h2 class="blog__title fw5">${blog.title}</h2>
          </div>
          <a
            class="blog-card__link--hover link gray pa3  fw5"
            @click=${this.shareOnFacebook(blog)}
          >
            <div class="flex align--center">
              <i class="blog-card__icon material-icons">share</i>
              <label>Share</label>
            </div>
          </a>
        </div>
      `;
    }

    private getCoverImage(blog: Blog) {
      return html`
        <img src=${blog.image || 'images/blog.jpg'} width="100%" />
      `;
    }

    private getText(blog: Blog) {
      const doc = new DOMParser().parseFromString(blog.text, 'text/html');
      return doc.firstChild;
    }

    private render(blog: Blog) {
      render(this.getContent(blog), this.contentTarget);
    }
  };

Application.start().register('blog', blogControllerFactory(Config.URL(env)));
