export enum Env {
  DEV = 'dev-api',
  STAGING = 'staging',
  PROD = 'api',
}

const getEnv = () => {
  switch (process.env.ENV) {
    case 'dev':
      return Env.DEV;
    case 'staging':
      return Env.STAGING;
    case 'production':
      return Env.PROD;
    default:
      return Env.DEV;
  }
}

export const env = getEnv();
