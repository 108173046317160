import { Props } from '../../../../types';
import { html } from 'lit-html';

import './time-box.scss';

export interface TimeBoxProps extends Props {
  amount: number;
  type: 'days' | 'hours' | 'minutes' | 'seconds';
}

export const timeBox = (props: TimeBoxProps) => {
  const { amount, type } = props;

  const amountComponent = html`
    <label class="time-box f2">${amount < 10 ? `0${amount}` : amount}</label>
  `;

  const typeComponent = html`
    <label class="f6 gray fw4">${type}</label>
  `;

  return html`
    <div class="flex flex-column align--center">
      ${amountComponent} ${typeComponent}
    </div>
  `;
};
