import { Controller, Application } from 'stimulus';
import { html, render } from 'lit-html';

import '../../blogs';
import './blogs.scss';
import { API, FetchClient, env } from '../../../services';
import { Config } from '../../../config';
import { Blog } from './blog.model';

import { blogCard } from '../../components/shared/blog-card.component';
import { RoutingService } from '../../../services/routing.service';
import { Route } from '../../../router/routes';

const blogsControllerFactory = (url: string) =>
  class BlogsController extends Controller {
    static targets = ['content'];

    private contentTarget: HTMLElement;

    initialize() {
      const api = new API(new FetchClient(url));

      api.getBlogs().then(blogs => {
        // this.render(blogs);
        RoutingService.router.navigate(Route.BLOG, {
          slug: 'knowledge-sharing-app-tyta-going-live-soon',
        });
      });
    }

    private getContent(blogs: Blog[]) {
      return html`
        <div class="flex flex-wrap">
          ${blogs.map(
            blog =>
              html`
                <div class="blogs_card">
                  ${blogCard({
                    blog: blog,
                    className: '',
                  })}
                </div>
              `,
          )}
        </div>
      `;
    }

    private render(blogs: Blog[]) {
      render(this.getContent(blogs), this.contentTarget);
    }
  };

Application.start().register('blogs', blogsControllerFactory(Config.URL(env)));
