import { createRouter } from 'router5';
import { routes } from '.';
import browserPlugin from 'router5-plugin-browser';

export const initRouter = () => {
  const router = createRouter(routes);

  router.usePlugin(
    browserPlugin({
      useHash: true,
    }),
  );

  return router;
};
