import { ElementSelector } from '../../../types';
import { Controller, Application } from 'stimulus';
import { addClass, removeClass, click } from '../../../utils/dom';

import './about.scss';

enum Target {
  EASY_TO_USE = 'easyToUse',
  TRANSPARENT = 'transparent',
  FAIR = 'fair',
}

const factory = ($: ElementSelector) =>
  class AboutController extends Controller {
    static targets = [
      'easyToUse',
      'transparent',
      'fair',
      'easyToUseText',
      'transparentText',
      'fairText',
    ];

    private easyToUseTarget: HTMLElement;
    private transparentTarget: HTMLElement;
    private fairTarget: HTMLElement;
    private easyToUseTextTarget: HTMLElement;
    private transparentTextTarget: HTMLElement;
    private fairTextTarget: HTMLElement;

    private activate = addClass('about--activate');
    private deactivate = removeClass('about--activate');

    private show = removeClass('about--hide');
    private hide = addClass('about--hide');

    connect() {
      this.attachListeners();
      this.showInitText();
    }

    private showInitText() {
      this.showText(Target.EASY_TO_USE);
      this.activate(this.easyToUseTarget);
    }

    attachListeners() {
      click(this.easyToUseTarget, this.onActivate(Target.EASY_TO_USE));
      click(this.transparentTarget, this.onActivate(Target.TRANSPARENT));
      click(this.fairTarget, this.onActivate(Target.FAIR));
    }

    private showText(target: Target) {
      switch (target) {
        case Target.EASY_TO_USE:
          this.show(this.easyToUseTextTarget);
          this.hide(this.transparentTextTarget);
          this.hide(this.fairTextTarget);
          break;
        case Target.TRANSPARENT:
          this.show(this.transparentTextTarget);
          this.hide(this.easyToUseTextTarget);
          this.hide(this.fairTextTarget);
          break;
        case Target.FAIR:
          this.show(this.fairTextTarget);
          this.hide(this.transparentTextTarget);
          this.hide(this.easyToUseTextTarget);
          break;
        default:
          throw Error('Target not defined');
      }
    }

    private onActivate(target: Target) {
      switch (target) {
        case Target.EASY_TO_USE:
          return () => {
            this.activate(this.easyToUseTarget);
            this.deactivate(this.fairTarget);
            this.deactivate(this.transparentTarget);
            this.showText(Target.EASY_TO_USE);
          };
        case Target.FAIR:
          return () => {
            this.activate(this.fairTarget);
            this.deactivate(this.easyToUseTarget);
            this.deactivate(this.transparentTarget);
            this.showText(target);
          };
        case Target.TRANSPARENT:
          return () => {
            this.activate(this.transparentTarget);
            this.deactivate(this.fairTarget);
            this.deactivate(this.easyToUseTarget);
            this.showText(target);
          };
        default:
          throw Error('Target not defined');
      }
    }
  };

const controller = factory(document.querySelector.bind(document));
Application.start().register('about', controller);
