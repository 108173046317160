import './subscribers.scss';
import { html } from 'lit-html';

export const subscribers = (subscribers: number, text: ChildNode) => {
  return html`
    <div class="subscribers__container flex flex-column w-100">
      <div class="flex w-100 justify--center">
        <p class="white f3">
          ${text}
        </p>
      </div>
      <div class="flex w-100">
        <div class="flex flex-column w-50 align--center">
          <i class="material-icons">
            people
          </i>
          <label class="white f6">SUBSCRIBERS</label>
          <label class="white">${subscribers}</label>
        </div>
        <div class="flex justify--center w-50">
          <a href="#form" class="self-center no-underline w-100">
            <button
              class="bg-accent w4 hero-button white bn shadow-2 mv4 f6 mdc-button form-btn"
            >
              <b class="fw5 ttu" data-i18n="[html]index.hero.button">
                LET'S GO</b
              >
            </button></a
          >
        </div>
      </div>
    </div>
  `;
};
