import { Controller, Application } from 'stimulus';
import { html, render } from 'lit-html';

import './our-team.scss';

const ourTeamControllerFactory = () =>
  class OurTeamController extends Controller {
    static targets = ['content'];

    private contentTarget: HTMLElement;

    connect() {
      this.render();
    }

    private render() {
      render(this.getContent(), this.contentTarget);
    }

    private getContent() {
      const secondMemberText = `
        At the age of six Victor traveled from Ghana to live in the Netherlands.
         Adjusting to the Dutch system wasen't always easy but he proved to be a quick learner.
         However, during high school he was in need of tutoring help for some subjects.
         After beeing tutored he learned that struggling students can get to the same level as the brightest students, as long as they are not afraid to ask for help. After succesfully completing high school he decided to follow Econometrics at the university. Here the tables turned, he was now the one tutoring others. He helped other students with mathematics and finance. He went to do this for about 4 years. In those 4 years his strong believe for peer to peer studying grew. Now as a young professional, his urge to easen the studying experience, for especially students who are not familiar with the dutch education system, has grown even more. Hence the birth of TyTa.`;
      const secondMemberImage = 'images/second-member.jpg';

      const firstMemberText = `
        Born in the Netherlands to non Dutch parents, Drazen was always eager to put his best foot forward at school.
        He does not believe in excuses but in hard work.
        This led to high grades during high school.
        After completing high school he decided to follow Econometrics at the university.
        Here Drazen learned that individual succes is great, but more satisfation can be achieved by helping others.
        He helped others in his direct environment who were struggling with mathematics, statistics and economics.
        As a tutor he learned as much from the other students as they did from him.
        Till this day he never forgot this experience.
        This led him connecting with his long time friend Victor to start creating TyTa.
        `;

      const firstMemberImage = 'images/first-member.jpg';

      return html`
        <div class="flex flex-column align--center">
          ${this.getCoverVideo()}
          <div class="our-team__members--container flex mw8 w-100 flex-wrap">
            ${this.getMember(firstMemberImage, 'Drazen Pesjak', firstMemberText)}
            ${this.getMember(secondMemberImage, 'Victor Amankwah', secondMemberText)}
          </div>
        </div>
      `;
    }

    private getCoverVideo() {
      return html`
      <iframe 
        class='our-team__cover-video'
        src="https://www.youtube.com/embed/ZUM-vv2wdKI"
        frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
      `;
    }

    private getMember(image: string, name: string, text: string) {
      return html`
        <div class="our-team__member flex flex-column">
          <img width="250px" height="250px" src=${image} />
          <h2 class='fw4'>${name}</h2>
          <p class='p gray f7'>${text}</p>
        </div>
      `;
    }
  };

Application.start().register('our-team', ourTeamControllerFactory());
