import { Props } from '../../../types';
import { html } from 'lit-html';

export const cardFooter = (props: Props) => {
  const { children, className, style } = props;
  const classes = `divider ${className ? className : ''}`;
  return html`
    <footer class=${classes} style=${style}>${children}</footer>
  `;
};
