import { html } from 'lit-html';
import { Props } from '../../../types';
import { RoutingService } from '../../../../services/routing.service';
import { Route } from '../../../../router/routes';
import { addClass, removeClass } from '../../../../utils/dom';

interface FeedCardProps extends Props {
  image: string;
  icon: string;
  label: string;
  goTo: Route;
}

export const card = (props: FeedCardProps) => {
  const { image, icon, goTo, label, className, style } = props;

  const styleBgImage = `background-image: url('${image}'); height: 100%; background-size: cover; background-repeat: no-repeat;`;
  const customStyle = `${style || ''} ${styleBgImage}`;

  return html`
    <div class=${className} style=${customStyle} @click=${navigate(goTo)}>
      <div class="feed__card flex flex-column justify--center align--center ">
        <i class="material-icons white f1">${icon}</i>
        <label class="white">${label}</label>
      </div>
    </div>
  `;
};

const navigate = (route: Route | string) => (_e: Event) => {
  const ourTeam = document.querySelector('#our-team-ns')!;
  const blogs = document.querySelector('#blogs-ns')!;
  const home = document.querySelector('#home-ns')!;

  removeClass('active')(home);

  switch (route) {
    case Route.BLOGS:
      addClass('active')(blogs);
      break;
    case Route.OUR_TEAM:
      addClass('active')(ourTeam);
      break;
  }

  RoutingService.router.navigate(route as string);
};
