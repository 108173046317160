import { API, FetchClient, Intl, Language, SimpleLogger } from '../services';
import { env } from '../services/environment';
import { addClass, click, removeClass } from '../utils/dom';
import { polyfills } from '../polyfills';
import { ElementSelector, ElementsSelector, Logger } from '../types';
import { configureConsole } from '../utils/console';
import { Scrolr } from './components/scrolr';
import { Contact } from './components/contact';
import { Config } from '../config';

import 'material-icons';

import feed from '../../feed.pug';
import about from '../../about.pug';
import features from '../../features.pug';
import blogs from '../../blogs.pug';
import blog from '../../blog.pug';
import ourTeam from '../../our-team.pug';

// controllers
import './controllers/blogs/blogs.controller';
import './controllers/blogs/blog/blog.controller';
import './controllers/features.controller';
import './controllers/feed/feed.controller';
import './controllers/our-team/our-team.controller';
import './controllers/about/about.controller';

// import for sideffects
import 'tachyons';
import 'typeface-roboto';
import 'normalize.css';
import '../../assets/css/fontello/css/icon.css';

import { Route } from '../router';
import { Router } from 'router5';
import { RoutingService } from '../services/routing.service';

interface Elements {
  intl: {
    en: HTMLElement;
    nl: HTMLElement;
  };
  nav: {
    about: HTMLElement;
    features: HTMLElement;
    logo: HTMLElement;
    home: HTMLElement;
    blogs: HTMLElement;
    ourTeam: HTMLElement;
    homeSm: HTMLElement;
    aboutSm: HTMLElement;
    featuresSm: HTMLElement;
    blogSm: HTMLElement;
    ourTeamSm: HTMLElement;
  };
  buttons: {
    contact: HTMLElement;
    fullWcontact: HTMLElement;
    footerContact: HTMLElement;
    close: HTMLElement;
  };
  app: HTMLElement;
}

class App {
  private scrolr: Scrolr;
  private localize: Intl;
  private contact: Contact;
  private router: Router;
  private elements: Elements;

  private selectedLanguage: Language;

  constructor($: ElementSelector, _$$: ElementsSelector, logger: Logger) {
    this.api = new API(new FetchClient(Config.URL(env)));
    this.contact = new Contact($);
    this.scrolr = new Scrolr($);
    this.localize = Intl.instance;

    this.selectedLanguage = Language.EN;

    this.elements = this.initialize($);
    this.showApp();
    this.initFbShare();
    this.router = RoutingService.router;
    //routing
    this.startRouting();
  }

  public startRouting() {
    this.handleFbClid();
    this.router.subscribe(listener => {
      this.getPage(listener.route.name);
    });
    this.goToInitRoute();
  }

  private handleFbClid() {
    if (location.href.includes('fbclid')) {
      const blogSlugSplitter = location.href.split('/blog/');
      const blogSlug =
        blogSlugSplitter && blogSlugSplitter[blogSlugSplitter.length - 1];
      if (blogSlug) {
        this.router.navigate(Route.BLOG, { slug: blogSlug });
        return;
      }
      this.goTo(Route.FEED);
    }
  }

  private initFbShare() {
    window.fbAsyncInit = function() {
      FB.init({
        appId: '142349383018807',
        status: true,
        cookie: true,
        xfbml: true,
      });
    };
    (function() {
      var e = document.createElement('script');
      e.async = true;
      e.src =
        document.location.protocol + '//connect.facebook.net/en_US/all.js';
      document.getElementById('fb-root')!.appendChild(e);
    })();
  }
  private getPage(route: Route | string) {
    const { app } = this.elements;
    switch (route) {
      case Route.FEED:
        app.innerHTML = feed;
        break;
      case Route.BLOGS:
        app.innerHTML = blogs;
        break;
      case Route.BLOG:
        app.innerHTML = blog;
        break;
      case Route.OUR_TEAM:
        app.innerHTML = ourTeam;
        break;
      default:
        throw Error('Unknown root');
    }
    this.localize.change(this.selectedLanguage, true);
  }

  private goToInitRoute() {
    const initRouteSplitted = location.hash.split('/');
    const initRoute = initRouteSplitted ? initRouteSplitted[1] : Route.FEED;
    this.activeProperNavButton((initRoute as Route) || Route.FEED);
    this.getPage(initRoute || Route.FEED);
  }

  private activeProperNavButton(route: Route) {
    const { about, features, ourTeam, blogs, home } = this.elements.nav;
    const active = addClass('active');

    switch (route) {
      case Route.ABOUT:
        active(about);
        break;
      case Route.FEATURES:
        active(features);
        break;
      case Route.OUR_TEAM:
        active(ourTeam);
        break;
      case Route.BLOGS:
        active(blogs);
        break;
      case Route.FEED:
        active(home);
        break;
    }
  }

  private goTo(route: string) {
    this.router.navigate(route);
  }

  public listen = (): void => {
    this.attachListeners(this.elements);

    this.scrolr.observe();
  };

  private showApp() {
    document.querySelector('#root')!.style.display = 'flex';
  }

  private initialize = ($: ElementSelector): Elements => {
    return {
      intl: {
        en: $<HTMLButtonElement>('#en')!,
        nl: $<HTMLButtonElement>('#nl')!,
      },
      nav: {
        about: $<HTMLAnchorElement>('#about-ns')!,
        features: $<HTMLAnchorElement>('#features-ns')!,
        logo: $<HTMLAnchorElement>('#logo')!,
        blogs: $<HTMLAnchorElement>('#blogs-ns')!,
        ourTeam: $<HTMLAnchorElement>('#our-team-ns')!,
        home: $<HTMLAnchorElement>('#home-ns')!,
        homeSm: $<HTMLAnchorElement>("#home-sm")!,
        aboutSm: $<HTMLAnchorElement>('#about-sm')!,
        featuresSm: $<HTMLAnchorElement>('#features-sm')!,
        blogSm: $<HTMLAnchorElement>('#blog-sm')!,
        ourTeamSm: $<HTMLAnchorElement>('#our-team-sm')!,
      },
      buttons: {
        contact: $<HTMLAnchorElement>('#contactBtn')!,
        fullWcontact: $<HTMLAnchorElement>('#fullwctnbtn')!,
        footerContact: $<HTMLSpanElement>('#footercontact')!,
        close: $<HTMLButtonElement>('#closebtn')!,
      },
      app: $<HTMLElement>('#app')!,
    };
  };

  private attachListeners = (elements: Elements): void => {
    const { intl, buttons, form, nav } = elements;

    click(intl.en, this.handleIntlButton('en'));
    click(intl.nl, this.handleIntlButton('nl'));

    click(buttons.contact, this.handleContactButton);
    click(buttons.fullWcontact, this.handleContactButton);
    click(buttons.footerContact, this.handleContactButton);
    click(buttons.close, this.contact.close);

    click(nav.home, this.handleNavButton(Route.FEED));
    click(nav.about, this.handleNavButton(Route.FEED));
    click(nav.features, this.handleNavButton(Route.FEED));
    click(nav.logo, this.handleNavButton(Route.FEED));
    click(nav.blogs, this.handleNavButton(Route.BLOGS));
    click(nav.ourTeam, this.handleNavButton(Route.OUR_TEAM));

    click(nav.homeSm, this.closeDropMenu.bind(this));
    click(nav.aboutSm, this.closeDropMenu.bind(this));
    click(nav.featuresSm, this.closeDropMenu.bind(this));
    click(nav.blogSm, this.closeDropMenu.bind(this));
    click(nav.ourTeamSm, this.closeDropMenu.bind(this));
    click(buttons.contact, this.closeDropMenu.bind(this));
  };

  private closeDropMenu() {
    const menu: HTMLInputElement = document.getElementById(
      'toggle',
    )! as HTMLInputElement;
    menu.checked = false;
    if (this.router.getState().name === Route.FEED) {
      return;
    }
    this.goTo(Route.FEED);
  }

  private handleIntlButton = (intl: 'en' | 'nl'): EventListener => {
    const activate = addClass('active');
    const deactivate = removeClass('active');

    const { en, nl } = this.elements.intl;

    switch (intl) {
      case 'en': {
        return () => {
          activate(en);
          deactivate(nl);
          this.selectedLanguage = Language.EN;
          this.localize.change(Language.EN);
        };
      }
      case 'nl': {
        return () => {
          activate(nl);
          deactivate(en);
          this.selectedLanguage = Language.NL;
          this.localize.change(Language.NL);
        };
      }
      default: {
        throw new Error('Unknown language!');
      }
    }
  };

  private handleContactButton = (ev: Event): void => {
    ev.preventDefault();
    this.contact.toggle();
  };

  private handleNavButton = (btn: Route): EventListener => {
    const activate = addClass('active');
    const deactivate = removeClass('active');

    const { about, features, blogs, home, ourTeam } = this.elements.nav;

    switch (btn) {
      case Route.FEED: {
        return () => {
          activate(home);
          deactivate(blogs);
          deactivate(about);
          deactivate(features);
          deactivate(ourTeam);
          if (this.router.getState().name === Route.FEED) {
            return;
          }
          this.goTo(Route.FEED);
        };
      }
      case Route.BLOGS: {
        return () => {
          activate(blogs);
          deactivate(about);
          deactivate(features);
          deactivate(ourTeam);
          deactivate(home);
          this.goTo(Route.BLOGS);
        };
      }
      case Route.OUR_TEAM: {
        return () => {
          activate(ourTeam);
          deactivate(about);
          deactivate(features);
          deactivate(blogs);
          deactivate(home);
          this.goTo(Route.OUR_TEAM);
        };
      }
      default: {
        throw new Error('Unknown navigation button!');
      }
    }
  };
}

const startApp = (): void => {
  const $ = document.querySelector.bind(document);
  const $$ = document.querySelectorAll.bind(document);
  const logger = new SimpleLogger(env);

  try {
    new App($, $$, logger).listen();
  } catch (error) {
    logger.error(error.message);
  }
};

window.onload = () => {
  polyfills().then(() => {
    configureConsole(env);
    startApp();
  });
};
