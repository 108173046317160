import { Slider } from '../components/slider';
import { ElementSelector } from '../../types';
import { Controller, Application } from 'stimulus';

const factory = ($: ElementSelector) =>
  class FeaturesController extends Controller {
    initialize() {
      new Slider($);
    }
  };

const controller = factory(document.querySelector.bind(document));
Application.start().register('features', controller);
