import { Blog } from '../../controllers/blogs/blog.model';
import { Props } from '../../types';
import { html } from 'lit-html';
import { cardHeader } from './card/card-header.component';
import { cardBody } from './card/card-body.component';
import moment from 'moment';
import { cardFooter } from './card/card-footer.component';
import { Route } from '../../../router';
import { RoutingService } from '../../../services/routing.service';

interface BlogCardProps extends Props {
  blog: Blog;
}

export const blogCard = (props: BlogCardProps, smallCard?: boolean) => {
  const { className, style, blog } = props;

  let headerChildren = html`
    <img
      width="100%"
      height=${smallCard ? '125px' : '261px'}
      src="images/blog.jpg"
    />
  `;

  if (blog.image) {
    headerChildren = html`
      <img
        width="100%"
        height=${smallCard ? '125px' : '261px'}
        src=${blog.image}
      />
    `;
  }

  if (blog.video) {
    const videoId = `video-player-iframe-${blog.id}`;
    headerChildren = html`
      <!-- <div class='blog-card__video--clicker' @click=${onVideoClick(
        blog.id,
      )}>
      </div> -->
      <iframe
        width="100%"
        height=${smallCard ? '125px' : '261px'}
        src=${blog.video}
        id=${videoId}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      >
      </iframe>
    `;
  }

  const bodyData = smallCard ? blog.text : blog.short_description;

  const doc = new DOMParser().parseFromString(
    bodyData || 'No information provided',
    'text/html',
  );

  const titleClass = smallCard
    ? 'blog_card__title--small fw6'
    : 'blog_card__title f6';

  const timeClass = smallCard
    ? 'section-text gray blog-card__time--small'
    : ' section-text gray blog-card__time';

  const descriptionClass = smallCard
    ? 'section-text blog-card__description--small'
    : 'section-text f7 blog_card__description';

  const body = html`
    <div class="">
      <label class=${timeClass}
        >${moment(blog.created_at).format('Do MMMM, YYYY')}</label
      >
      <p class=${titleClass}>
        ${blog.title}
      </p>
      <div class=${descriptionClass}>
        ${doc.firstChild}
      </div>
    </div>
  `;

  const shareSpan = !smallCard
    ? html`
        <span class="fw6">Share</span>
      `
    : '';

  const readButton = !smallCard
    ? html`
        <a class="blog-card__link--hover link gray fw5" @click=${onRead(blog)}>
          <div class="flex align--center">
            <i class="blog-card__icon blog-card__icon--text material-icons fw5"
              >short_text</i
            >
            <label class="fw6">Read</label>
          </div></a
        >
      `
    : '';

  const footer = html`
    <div
      class="flex justify--space-between align--center blog-card__footer"
      style="height: 65px;"
    >
      <a
        class="blog-card__link--hover link gray fw5"
        @click=${shareOnFacebook(blog, smallCard)}
      >
        <div class="flex align--center">
          <i class="blog-card__icon material-icons fw5">share</i>
          ${shareSpan}
        </div>
      </a>
      ${readButton}
    </div>
  `;

  const customClass = `blog-card blog-card--rounded flex flex-direction--column ${className ||
    ''}`;

  const bodyClass = smallCard
    ? 'pb3 pt3 pl3 pr3 h-100 roboto'
    : 'pb3 pt3 pl4 pr4 h-100 roboto';

  return html`
    <div class=${customClass} style=${style || ''}>
      ${cardHeader({ children: headerChildren })}
      ${cardBody({ children: body, className: bodyClass })}
      ${cardFooter({ children: footer })}
    </div>
  `;
};

const onRead = (blog: Blog) => (e: Event) => {
  RoutingService.router.navigate(Route.BLOG, { slug: blog.slug });
};

const shareOnFacebook = (blog: Blog, smallCard?: boolean) => (e: Event) => {
  const url = smallCard ? document.URL : `${document.URL}/#/blog/${blog.slug}`;

  e.preventDefault();
  FB.ui({
    method: 'share',
    name: blog.title,
    href: url,
    picture:
      blog.image ||
      'https://images.pexels.com/photos/414612/pexels-photo-414612.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500',
    description: blog.short_description || 'No description found.',
    message: '',
  });
  return false;
};

const onVideoClick = (id: number) => (e: any) => {
  const player: HTMLIFrameElement = document.getElementById(
    `video-player-iframe-${id}`,
  )! as HTMLIFrameElement;
  if (~player.src.indexOf('?')) player.src += '&autoplay=1';
  else player.src += '?autoplay=1';

  var req =
    player.requestFullscreen ||
    player.webkitRequestFullscreen ||
    player.mozRequestFullScreen ||
    player.msRequestFullscreen;

  req.call(player);
  e.target.style.display = 'none';
  e.preventDefault();
};
