import curry from 'just-curry-it';

const event = (ev: string) => (elem: Element, handler: EventListener) => {
  elem.addEventListener(ev, handler);
};

export const addClass = curry((cls: string, el: HTMLElement) =>
  el.classList && el.classList.add(cls),
);
export const removeClass = curry((cls: string, el: HTMLElement) =>
  el.classList && el.classList.contains(cls) && el.classList.remove(cls),
);

export const click = event('click');
export const submit = event('submit');
