import { HTTPClient } from '../types';
import { Blog } from '../pages/controllers/blogs/blog.model';
import { Feed } from '../pages/controllers/feed/feed.model';

export class API {
  constructor(private http: HTTPClient) {}

  public subscribe = <T>(data: any): Promise<T> => {
    return this.http.post('/mailing-list', { body: JSON.stringify(data) });
  };

  public resetPassword = <T>(data: any): Promise<T> => {
    return this.http.put('/reset-password', { body: JSON.stringify(data) });
  };

  public getBlogs = (): Promise<Blog[]> => {
    return this.http.get('/blog-posts', {}).then((res: any) => res.data);
  };

  public getBlog = (slug: string): Promise<Blog> => {
    return this.http.get(`/blog/${slug}`, {}).then((res: any) => res.data);
  };

  public getTimer = () => {
    return this.http.get('/timer', {}).then((res: any) => res.data);
  };

  public getSubscribers = () => {
    return this.http.get('/subscribers', {}).then((res: any) => res.data);
  };

  public getNews = () => {
    return this.http.get('/news-posts?limit=4', {}).then((res: any) => res.data);
  };

  public getFeed = (): Promise<Feed> => {
    return Promise.all([
      this.getNews(),
      this.getTimer(),
      this.getSubscribers(),
    ]).then((res: any[]) => {
      return {
        blogs: res[0],
        timer: res[1],
        subscribers: res[2],
      };
    });
  };
}
