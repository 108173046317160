export enum Route {
  FEED = 'feed',
  ABOUT = 'about',
  FEATURES = 'features',
  BLOGS = 'blogs',
  BLOG = 'blog',
  OUR_TEAM = 'our-team',
}

export const routes = [
  { name: Route.FEED, path: '/' },
  { name: Route.ABOUT, path: '/about' },
  { name: Route.FEATURES, path: '/features' },
  { name: Route.BLOGS, path: '/blogs' },
  { name: Route.BLOG, path: '/blog/:slug' },
  { name: Route.OUR_TEAM, path: '/our-team' },
];
