import moment from 'moment';
import { html, render } from 'lit-html';
import { timeBox } from './time-box/time-box.component';

export const counter = (endTime: string) => {
  setInterval(() => {
    render(getDiffs(endTime), document.querySelector('#counter-container')!);
  }, 1000);
  return html`
    <div id="counter-container">${getDiffs(endTime)}</div>
  `;
};

const getDiffs = (endTime: string) => {
  const now = moment();
  const end = moment(endTime);

  const daysDiff = end.diff(now, 'days');
  const hoursDiff = end.diff(now, 'hours') - daysDiff * 24;
  const minutesDiff =
    end.diff(now, 'minutes') - daysDiff * 24 * 60 - hoursDiff * 60;
  const secondsDiff =
    end.diff(now, 'seconds') -
    daysDiff * 24 * 3600 -
    hoursDiff * 3600 -
    minutesDiff * 60;

  return html`
    <div class="flex justify--space-between">
      ${timeBox({ amount: daysDiff, type: 'days' })}${timeBox({
        amount: hoursDiff,
        type: 'hours',
      })}${timeBox({ amount: minutesDiff, type: 'minutes' })}${timeBox({
        amount: secondsDiff,
        type: 'seconds',
      })}
    </div>
  `;
};
