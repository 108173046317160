import { Router } from 'router5';
import { initRouter } from '../router';

export class RoutingService {
  private static instance: Router | null = null;

  private constructor() {}
  
  public static get router() {
    if (RoutingService.instance) {
      return RoutingService.instance;
    }
    RoutingService.instance = initRouter().start();
    return RoutingService.instance;
  }
}
