import { MDCTextField } from "@material/textfield";
import { IntlFn, API } from "../../services";
import { ElementSelector, IErrorResponse, ILogger } from "../../types";

enum Status {
  SUCCESS,
  ERROR
}

enum Duration {
  SHORT = 1200,
  LONG = 2000
}

type MaybeInputElement = HTMLInputElement | null;

export class Form {
  private name: HTMLInputElement;
  private email: HTMLInputElement;
  private message: HTMLInputElement;
  private snack: HTMLElement;
  private _form: HTMLFormElement;

  constructor(
    private $: ElementSelector,
    private t: IntlFn,
    private api: API,
    private logger: ILogger
  ) {
    this.name = new MDCTextField($("#name--c"));
    this.email = new MDCTextField($("#email--c"));
    this.message = new MDCTextField($("#message--c"));
    this.snack = $<HTMLElement>(".snack")!;
    this._form = $("form")!;
  }

  private get interest(): string {
    const el: MaybeInputElement = this.$('input[name="interested"]:checked');

    if (!el) {
      return "";
    }

    return el.value;
  }

  private get form() {
    return {
      name: this.name.value,
      email: this.email.value,
      interest: this.interest,
      message: this.message.value
    };
  }

  public reset = () => {
    this._form.reset();
  };

  private logException = (err: IErrorResponse): void => {
    this.logger.error(err.message);
  };

  private toast(status: Status, duration: Duration = Duration.LONG): void {
    switch (status) {
      case Status.SUCCESS: {
        this.snack.innerHTML = this.t("index.toast.success");
        this.snack.classList.add("primary");
        break;
      }
      case Status.ERROR: {
        this.snack.innerHTML = this.t("index.toast.error");
        this.snack.classList.add("error");
        break;
      }
    }
    this.snack.classList.add("on");
    setTimeout(() => this.snack.classList.remove("on"), duration);
  }

  public submit = () => {
    debugger;
    this.api
      .subscribe(this.form)
      .then(() => this.toast(Status.SUCCESS))
      .catch((err: Response) => {
        this.toast(Status.ERROR);
        err.json().then(this.logException);
      });
  };
}
